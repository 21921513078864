import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';
import AppIcon from '../components/AppIcon';

import pslogo from '../assets/images/plebiantstudios_logo.png';
import demo1 from '../assets/games/colorbills/colorbills-6s-1.jpg';
import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';

const link = {
  page: 'colorbills',
  ios: 'https://apps.apple.com/us/app/colorbills/id1495279433'
};

const ColorbillsPage = () => (
  <Layout>

    <header className="masthead colorbills">
      <div className="container vh-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto text-center blur-white">
              <h1 className="section-heading text-center font-weight-bold">
                Colorbills
              </h1>
              <br />
              <h3 className="mb-5">
              Cute, and colorful hornbills!

              </h3>
              {link.ios && <a className="badge-link" href={link.ios}>
                    <img style={{width: 200, margin: 20}} src={astore} alt="" />
                </a>}
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait dark">
                <div className="device">
                  <div className="screen">
                    <img src={demo1} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <Footer />

  </Layout>
  );

export default ColorbillsPage;